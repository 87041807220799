import {
  ApiBlackoutPeriodFeatureID,
  ApiGroupInviteLink,
  ApiGroupMarkerType,
  ApiHRMGuidanceContactInfo,
  ApiHRMGuidanceContent,
  ApiMetricRatingLimits,
  ApiTeamsAgreement,
  CoachRole,
} from '../api/types';
import { MeasurementValue } from './measurements';
import { MetricKey } from './metrics';
import {
  MEDIUM_GROUP_MAX_MEMBER_COUNT,
  SMALL_GROUP_MAX_MEMBER_COUNT,
} from './researchConstants';
import { GroupMemberNotes } from './types';

// ORGANIZATIONS
export type OrganizationType =
  | 'coach'
  | 'research'
  | 'hrm_anonymous'
  | 'hrm_non_anonymous';

const HRM_ORG_TYPES = new Set<OrganizationType>([
  'hrm_non_anonymous',
  'hrm_anonymous',
]);

export function isHRMOrganization(org?: OrganizationSummary) {
  return !!org && HRM_ORG_TYPES.has(org.organizationType);
}

export interface OrganizationSummary {
  name: string;
  id: string;
  organizationType: OrganizationType;
  ownCoachID: string;
  createdAt: Date;
}

export interface Organization extends OrganizationSummary {
  groups: Group[];
  invitations: TeamsInvitation[];
  coaches: Coach[];
  terms: TermsStatus;
  usedSeatCounts: OrganizationUsedSeatCounts;
}

export interface OrganizationSettings {
  newCoachNotifications: boolean;
  newGroupMemberNotifications: boolean;
  revokedGroupMembershipNotifications: boolean;
}

export enum OrganizationSubscriptionPlan {
  Legacy,
  Teams,
  HRM,
}

export interface OrganizationSubsriptionOption {
  validFrom: Date;
  validTo?: Date;
  memberSeatCount?: number;
  blackoutMode?: boolean;
}

export interface OrganizationSubscription {
  id: string;
  plan: OrganizationSubscriptionPlan;
  isTrial: boolean;
  validFrom: Date;
  validTo?: Date;
  options: OrganizationSubsriptionOption[];
}

export interface TermsStatus {
  latestVersion: string;
  acceptedVersion: string | null;
}

export interface OrganizationUsedSeatCounts {
  member: number;
}

// GROUPS

export enum GroupSize {
  Small,
  Medium,
  Large,
}

export function getGroupSize(memberCount: number) {
  if (memberCount <= SMALL_GROUP_MAX_MEMBER_COUNT) {
    return GroupSize.Small;
  }

  if (memberCount <= MEDIUM_GROUP_MAX_MEMBER_COUNT) {
    return GroupSize.Medium;
  }

  return GroupSize.Large;
}

export type GroupRoleType = 'viewer' | 'manager';
export interface GroupRole {
  id: string;
  coachID: string;
  role: GroupRoleType;
  createdAt: Date;
}

export enum GroupDataAccessLevel {
  AllTime = 'all_time',
  ParticipationPeriod = 'participation_time',
}
export interface GroupConfig {
  dataAccess: {
    level: GroupDataAccessLevel;
  };
  redirects?: {
    join: {
      enabled: boolean;
      url?: string;
    };
  };
}

export interface Group {
  createdAt: Date;
  description: string;
  memberCount: number;
  name: string;
  id: string;
  inviteLink: GroupInviteLink;
  isAnonymous: boolean;
  autoGuidanceEnabled: boolean;
  adminAlertsEnabled: boolean;
  isPrivate: boolean;
  roles: GroupRole[];
  config: GroupConfig;
  employeeWellness?: boolean;
}

export interface EmployeeWellnessReport {
  uid: string;
  studyUid: string;
  startDate: Date;
  endDate: Date;
  createdAt: Date;
  updatedAt: Date;
  s3Prefix: string;
  filename: string;
  label: string;
}

type GroupInviteLink = ApiGroupInviteLink;

export interface GroupStats {
  memberJoins: Date[];
  memberLeaves: Date[];
}

export interface MemberDailyData {
  /* null if no data exists for that day, undefined if not fetched */
  [date: string]: MeasurementValue | null | undefined;
}

export interface GroupMembersDailyMetrics {
  expiresAt: {
    /**
     * This is the end date of the time period that's been requested.
     */
    [endDate: string]:
      | undefined
      | {
          /**
           * This timestamp is when the data for this metric has expired, after
           * which the data should be refetched from the server.
           */
          [metricID: string]: ExpirationTime | undefined;
        };
  };
  dailyData: {
    [memberID: string]:
      | undefined
      | {
          [metricID: string]: MemberDailyData | undefined;
        };
  };
}

type ExpirationTime = Date;

export type MetricRatingLimits = ApiMetricRatingLimits;
export interface MetricAggregates {
  baselineDayCount: number;
  baseline?: number;
  percentiles?: {
    0: number;
    5: number;
    95: number;
    100: number;
  };
  metricRatingLimits?: MetricRatingLimits;
}

export interface GroupAggregates {
  [memberID: string]: {
    [metricID: string]: MetricAggregates;
  };
}

// MEMBERSHIPS

export interface Coach {
  id: string;
  email: string;
  name: string;
  role: CoachRole;
}

export interface TeamsInvitation {
  id: string;
  email: string;
  recipientName: string;
  expiresAt: Date;
  sentAt: Date;
  isExpired: boolean;
  sender: {
    email: string;
    name: string;
    id: string;
  };
}

export interface TeamsInvitationGroup {
  active: TeamsInvitation[];
  expired: TeamsInvitation[];
}

export interface LatestSleepSummaryDate {
  memberID: string;
  latestSleepSummaryDate: Date | null;
}

export interface HRMScore {
  /**
   * Historical data does not have IDs for scores
   */
  id: string | null;
  /**
   * For non-anonymous groups the members without a score are also included in the response
   */
  score: number | null;
  status: HRMScoreStatus;
  sentGuidanceID: string | null;
  notificationsSentAt: Date[];
  reliableScore: boolean;
  /**
   * Not defined in anonymous groups
   */
  reviewed?: boolean;
  /**
   * Not defined in anonymous groups
   */
  memberID?: string;
}

export type HRMScoreStatus = 'not_sent' | 'sent' | 'opened' | 'acted_on';

type HRMGuidanceContent = ApiHRMGuidanceContent;
export type HRMGuidanceContactInfo = ApiHRMGuidanceContactInfo;

export interface HRMGuidance {
  id: string;
  validFrom: Date;
  validTo?: Date;
  threshold: number;
  content: HRMGuidanceContent;
  contactInfo: HRMGuidanceContactInfo;
}

export interface HRMGuidanceAnalytics {
  sent: number;
  opened: number;
  actedOn: number;
}

export interface HRMGroupAggregatedData {
  date: Date;
  scores: number[];
  membersSynced: number;
  memberCount: number;
  guidances: HRMGuidance[];
}

export interface OrgMemberSearchResult {
  email: string | null;
  name: string;
  memberID: string;
  groupID: string;
  joinedAt: Date;
}

export interface GroupMembership {
  id: string;
  // Missing for anonymous groups
  email?: string;
  name: string;
  joinedAt: Date;
  latestSync: Date | null;
  labelIDs: string[];
}

// GROUP DASHBOARD
export type MarkerType = ApiGroupMarkerType;
export type EnabledMarkers = { [marker in MarkerType]: boolean };

export interface ViewSettings {
  dateRangeDays: number;
  enabledMarkers: EnabledMarkers;
  selectedMetricSetID: string | null;
  metricSetUidsOrdered: string[];
}

export interface MetricSet {
  id: string;
  name: string;
  metrics: MetricKey[];
}

interface RiskScoreTrendDatum {
  value: number | undefined;
  date: Date;
}

export interface RiskScoreTrendData {
  scores: RiskScoreTrendDatum[];
}

export interface RiskDataIDType {
  type: 'score' | 'participant';
  id: string;
}

export type BlackoutPeriodFeatureID = ApiBlackoutPeriodFeatureID;

export interface BlackoutPeriod {
  id: string;
  validFrom: Date;
  validTo: Date;
  features: BlackoutPeriodFeatureID[];
  comment?: string;
}

export interface GroupLabel {
  id: string;
  name: string;
  color: string;
  description: string;
  createdAt: Date;
}

export interface GroupNotes {
  [participantId: string]: GroupMemberNotes | undefined;
}

export interface Invite {
  email: string;
  name?: string;
}

export type TeamsAgreement = ApiTeamsAgreement;

// Data Export

export interface DataExportGroup {
  id: string;
  name: string;
  createdAt: Date;
  isPrivate: boolean;
  isAnonymous: boolean;
  accessLevel: string;
}

export interface DataExportGroupLabel {
  id: string;
  name: string;
  color: string;
  createdAt: Date;
  groupName: string;
  groupId: string;
}

export interface DataExportMember {
  id: string;
  name: string;
}

export interface DataExportItem {
  time: Date;
  name: string;
  type: string;
  dataType: string;
  dateRange: string;
  status: string;
  downloadLink?: string;
  createdAt: Date;
  expiresAt: Date;
}

export interface OrganizationDataExportSettings {
  dataTypes: string[];
  groups: DataExportGroup[];
  labels: DataExportGroupLabel[];
  members?: DataExportMember[];
}
