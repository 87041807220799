import { parseISO } from 'date-fns';

function hasTimezoneInformation(timestamp: string) {
  /**
   * Returns true if the timestamp has timezone information, i.e. "Z", "+nn:00", or "-mm:00".
   */
  const [, time] = timestamp.split('T');
  return time && ['Z', '+', '-'].some((c) => time.includes(c));
}

export function parseTimestamp(serverTimestamp: string) {
  const timestamp = hasTimezoneInformation(serverTimestamp)
    ? serverTimestamp
    : /**
       * The server may send us timestamps that are in UTC but are missing the "Z" to
       * indicate this. Append "Z" to make JavaScript aware that the timestamps are in
       * UTC, otherwise it assumes it's in the user's browser's local time.
       */
      `${serverTimestamp}Z`;
  return parseISO(timestamp);
}

export { parseISO as parseDate } from 'date-fns';
